// src/components/Posts.js
import React from 'react';
import { Link } from "react-router-dom";


function Posts() {
    return (
        <div>
            <h2>Posts</h2>
            <p>Over time I plan to add more posts about what is most exciting to me in web3. </p>
            <ul>
                <li><Link to="/readinglist">Reading list</Link></li>
            </ul>
        </div>
    );
}

export default Posts;
