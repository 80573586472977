import React from 'react';
import './Home.css'; // Ensure you import your CSS file

function Home() {
    return (
        <div className="home-container">
            <h2 className="home-text">Hi, I'm Matt.</h2>
            <p className="home-text">
                I am a web3 developer.<br /><br />
                I think a lot about the future of the internet, web UX, and tools for thought. My core bet is on blockchains, and I am particularly interested in consumer applications related to Farcaster, stablecoins, and info finance.  <br /><br />
                Until recently, I was a Ph.D. student at the University of Minnesota, where I did neuroscience research. Most of my work was done in collaboration with UC Berkeley neuroscientist Terrence Deacon, and focused on how brains construct interpretive scaffolding and how this process differs from the logic of LLMs. Recently, I left the Ph.D. to start working on blockchain projects. <br /><br />
                Take a look around this website if you want a glimpse into what I have been reading or thinking about.<br /><br />
                If you are interested in what you find, please get in touch. <br /><br />
            </p>
        </div>
    );
}

export default Home;
