import React from "react";

function ReadingList() {
    return (
        <div style={{ textAlign: "left", padding: "20px", fontSize: "16px" }}>  {/* Ensure uniform text size */}
            <p style={{ textDecoration: "underline", fontWeight: "normal", marginBottom: "5px" }}>Reading list:</p>
            <p>My favorite people on the internet share their favorite sources of intellectual inspiration, so I am keeping a running list where I will do the same.</p>

            <p style={{ textDecoration: "underline", fontWeight: "normal", marginBottom: "5px" }}>Web3:</p>
            <div>
                <a href="https://danromero.org/" target="_blank" rel="noopener noreferrer">Dan Romero</a><br />
                <a href="https://brianarmstrong.com/" target="_blank" rel="noopener noreferrer">Brian Armstrong's Essays</a><br />
                <a href="https://medium.com/@FEhrsam" target="_blank" rel="noopener noreferrer">Fred Ehrsam Medium</a><br />
                <a href="https://varunsrinivasan.com/" target="_blank" rel="noopener noreferrer">Varun Srinivasan</a><br />
                <a href="https://jacob.energy/index.html" target="_blank" rel="noopener noreferrer">Jacob Horne</a><br />
                <a href="https://brave.com/brave-whitepaper/" target="_blank" rel="noopener noreferrer">Brave Whitepaper</a><br />
                <a href="https://bitcoin.org/bitcoin.pdf" target="_blank" rel="noopener noreferrer">Bitcoin Whitepaper</a><br />
                <a href="https://vitalik.ca/general/2022/01/26/soulbound.html" target="_blank" rel="noopener noreferrer">Soulbound by Vitalik</a> and 
                <a href="https://vitalik.ca/general/2022/12/19/souls.html" target="_blank" rel="noopener noreferrer"> Soul of Web3 by Vitalik & Glen Weyl</a><br />
                <a href="https://jonstokes.com/the-billion-user-table/" target="_blank" rel="noopener noreferrer">The Billion User Table, Jon Stokes</a><br />
                <a href="https://bridgeharris.substack.com/" target="_blank" rel="noopener noreferrer">Bridget Harris</a>
            </div>

            <p style={{ textDecoration: "underline", fontWeight: "normal", marginBottom: "5px" }}>Tools for Thought:</p>
            <div>
                <a href="https://andymatuschak.org/" target="_blank" rel="noopener noreferrer">Andy Matuschak</a> and 
                <a href="https://michaelnielsen.org/" target="_blank" rel="noopener noreferrer">Michael Nielsen</a><br />
                <a href="http://worrydream.com/" target="_blank" rel="noopener noreferrer">Everything Bret Victor</a><br />
                <a href="https://roamresearch.com/#/app/help/page/tCctSlpG0" target="_blank" rel="noopener noreferrer">ROAM WhitePaper</a><br />
                <a href="https://groups.csail.mit.edu/medg/people/psz/Licklider.html" target="_blank" rel="noopener noreferrer">Essays by JCR Licklider</a>
            </div>

            <p style={{ textDecoration: "underline", fontWeight: "normal", marginBottom: "5px" }}>Tech and Economics:</p>
            <div>
                <a href="https://pmarca.substack.com/" target="_blank" rel="noopener noreferrer">Marc Andreesen's Substack</a> and 
                <a href="https://pmarchive.com/" target="_blank" rel="noopener noreferrer">former blog</a><br />
                <a href="https://patrickcollison.com/" target="_blank" rel="noopener noreferrer">Patrick Collison</a><br />
                <a href="https://rootsofprogress.org/" target="_blank" rel="noopener noreferrer">Jason Crawford, Roots of Progress</a><br />
                <a href="https://www.noahpinion.blog/" target="_blank" rel="noopener noreferrer">Noah Smith’s Substack</a><br />
                <a href="https://cdixon.org/" target="_blank" rel="noopener noreferrer">Chris Dixon’s Blog</a><br />
                <a href="http://abovethecrowd.com/" target="_blank" rel="noopener noreferrer">Bill Gurley's Blog</a><br />
                <a href="https://stratechery.com/aggregation-theory/" target="_blank" rel="noopener noreferrer">Ben Thompson, Aggregation Theory</a> and 
                <a href="https://stratechery.com/concepts/" target="_blank" rel="noopener noreferrer"> More concepts</a><br />
                <a href="https://avc.com/" target="_blank" rel="noopener noreferrer">Fred Wilson Blog</a><br />
                <a href="https://www.ben-evans.com/presentations" target="_blank" rel="noopener noreferrer">Benedict Evans’ Annual Presentations</a><br />
                <a href="https://www.radicalxchange.org/" target="_blank" rel="noopener noreferrer">Glen Weyl: Radical Markets, Plurality, and “Why I’m not a ___” essays</a>
            </div>
        </div>
    );
}

export default ReadingList;
