// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'

function Navbar() {
    return (
        <nav>
            <div className="left-heading">mattrogers.xyz</div>
            <ul>
                <li><Link to="/">CV</Link></li>
                {/* <li><Link to="/bookshelf">Bookshelf</Link></li> */}
                <li><Link to="/posts">Posts</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;